//@Injectable() decorator marks it as a service that can be injected
import { Injectable } from "@angular/core";
//set api response file import
import { IApi } from "../shared/common/contracts/api";
//api methos explain
import { GenericApi } from "../shared/common/generic-api";
// /Most front-end applications communicate with backend services over the HTTP protocol.
// Modern browsers support two different APIs for making HTTP requests: the XMLHttpRequest interface and the fetch() API
import { HttpHeaders, HttpClient } from "@angular/common/http";
//xml model
import { Xml } from "../models/xml";
import { environment } from "../../environments/environment";
import "rxjs/add/operator/toPromise";
import { Observable } from "rxjs/Rx";

@Injectable()
export class XMLService {
  xmls: IApi<Xml>;
  values: IApi<Xml>;
  private rootUrl: string;
  onlyXmls: IApi<Xml>;
  constructor(private http: HttpClient) {
    this.xmls = new GenericApi<Xml>("xmls", http);
    this.values = new GenericApi<Xml>("xmls/values", http);
    this.onlyXmls = new GenericApi<Xml>("xmls/only/view", http);
    this.rootUrl = `${environment.apiUrls.api}`;
  }

  updateXml(xmlFields) {
    console.log(xmlFields);
    const headers = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    const body = JSON.stringify(xmlFields);
    return this.http.put(
      `${this.rootUrl}/xmls/update/column/${xmlFields.id}`,
      body,
      headers
    );
  }

  getXmlFields(id): any {
    return this.http.get(`${this.rootUrl}/xmls/${id}/getXmlFields`).toPromise();
  }

  getSingleXml(id): any {
    return this.http.get(`${this.rootUrl}/xmls/${id}`).toPromise();
  }

  getXmls(): any {
    return this.http.get(`${this.rootUrl}/xmls`).toPromise();
  }
  deleteXmls(id): any {
    return this.http.delete(`${this.rootUrl}/xmls/${id}`).toPromise();
  }
}
