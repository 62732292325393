export class Xml {
  id?: number = null;
  url = "";
  username = "";
  rootKey = "";
  fields: any = [];
  data: any[];
  inputType: "xml";
  apiKey: "";
  constructor() {}
  reset() {
    this.id = null;
    this.url = "";
    this.username = "";
  }
}

export class SearchReplace {
  search: String;
  replace: String;
  field: String;
}
