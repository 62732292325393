import { Component, OnInit } from "@angular/core";
import { XMLService } from "src/app/services/xml.service";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { Page } from "src/app/shared/common/contracts/page";
import { Xml } from "src/app/models/xml";

@Component({
  selector: "nmdx-list-xml",
  templateUrl: "./list-xml.component.html",
  styleUrls: ["./list-xml.component.css"],
})
export class ListXmlComponent implements OnInit {
  displayedColumns: string[] = [
    "id",
    "name",
    "url",
    "Created At",
    "Last Edit",
    "action",
  ];
  dataSource = [];
  rootUrl = "";
  serverUrl = "";
  isProcessing: boolean;
  xmls: Page<Xml>;
  constructor(private _xmlService: XMLService, private router: Router) {
    this.rootUrl = `${environment.apiUrls.api}`;
    this.serverUrl = environment.serverUrl;
    this.isProcessing = false;
    this.xmls = new Page({
      api: _xmlService.xmls,
      serverPaging: false,
    });
    this.fetch();
  }

  async ngOnInit() {
    const xmls = await this._xmlService.getXmls();
    this.dataSource = xmls.data;
    // console.log(xmls);
  }

  viewXml(id) {
    window.open(`${this.rootUrl}/xmls/parse2/${id}`, "_blank");
  }

  // async delete(id: number) {
  //   var isDelete = window.confirm('Are you sure want to delete ?')
  //   if (!isDelete) {
  //     return
  //   }
  //   // const xmls = await this._xmlService.xmls.remove(id);
  //   // this.dataSource = xmls.data;
  //   this.isProcessing = true;
  //    this. _xmlService.xmls.remove(id).then((xmls) => {
  //     this.router.navigate(['/pages']);
  //     this.isProcessing = false;
  //     // this.fetch();
  //   });
  // }

  fetch() {
    this.xmls.fetch();
  }

  delete(id: number) {
    const isDelete = window.confirm("Are you sure want to delete ?");
    if (!isDelete) {
      return;
    }
    this.xmls.isLoading = true;
    this._xmlService.xmls.remove(id).then(() => {
      this.xmls.isLoading = false;
      this.fetch();
    });
  }
}
